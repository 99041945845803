// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-legacy-component-typographies();
@include mat.core();

$md-primary: (
  50: #e2e4e9,
  100: #b7bcc7,
  200: #8890a2,
  300: #58637d,
  400: #344161,
  500: #102045,
  600: #0e1c3e,
  700: #0c1836,
  800: #09132e,
  900: #050b1f,
  A100: #5d78ff,
  A200: #2a4eff,
  A400: #0029f6,
  A700: #0025dc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-secondary: (
  50: #efe4e8,
  100: #d7bcc5,
  200: #bd909f,
  300: #a36478,
  400: #8f425b,
  500: #7b213e,
  600: #731d38,
  700: #681830,
  800: #5e1428,
  900: #4b0b1b,
  A100: #ff829b,
  A200: #ff4f72,
  A400: #ff1c49,
  A700: #ff0335,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$app-primary: mat.define-palette($md-primary);
$app-accent: mat.define-palette($md-secondary);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #102045 !important;
  --ion-color-primary-rgb: 56, 128, 255 !important;
  --ion-color-primary-contrast: #ffffff !important;
  --ion-color-primary-contrast-rgb: 255, 255, 255 !important;
  --ion-color-primary-shade: #102045 !important;
  --ion-color-primary-tint: #102045 !important;

  /** secondary **/
  --ion-color-secondary: #7b213e !important;
  --ion-color-secondary-rgb: 61, 194, 255 !important;
  --ion-color-secondary-contrast: #ffffff !important;
  --ion-color-secondary-contrast-rgb: 255, 255, 255 !important;
  --ion-color-secondary-shade: #7b213e !important;
  --ion-color-secondary-tint: #7b213e !important;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** green **/
  --ion-color-green: #42a948;
  --ion-color-green-rgb: 244, 245, 248;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #42a948;
  --ion-color-green-tint: #42a948;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

$new-primary: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: white,
  A200: white,
  A400: white,
  A700: white,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: white,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$new-secondary: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: white,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$new-app-primary: mat.define-palette($new-primary);
$new-app-accent: mat.define-palette($new-secondary);
$new-app-warn: mat.define-palette(mat.$red-palette);

$new-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $new-app-primary,
      accent: $new-app-accent,
      warn: $new-app-warn,
    ),
  )
);
