/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "swiper/swiper.min.css";

//@import "~swiper/scss";

ion-button {
  text-transform: none !important;
}

.primary-input {
  text-indent: 5px;
  width: 100%;
  --background: rgba(216, 214, 214, 0.562);
  background: rgba(216, 214, 214, 0.562);
  color: #000;
  min-height: 60px;
  line-height: 28px;
  border-radius: 5px;
  --border-radius: 5px;
  font-size: 16px;
  border-style: solid;
  border-color: transparent;
  z-index: 10000;
}

ion-item.primary-input {
  line-height: unset !important;
  min-height: unset !important;
}

@media screen and (max-width: 767px) {
  .mat-step-label {
    display: none !important;
  }
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

app-loading-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
}

app-loading-overlay.main-loader {
  background: #fff !important;
}

.language-icon {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.upper-label {
  position: relative;

  > ion-label {
    position: absolute;
    z-index: 100;
    top: 2px;
    left: 10px;
  }

  ion-input {
    padding-top: 10px !important;
  }

  select {
    padding-top: 12px !important;
    border-right: 16px solid transparent;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-form-field-required-marker {
  color: #f44336 !important;
}

.date-input {
  color: black;
}

.card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  ion-button {
    --border-radius: 0px;
    height: 40px;
    margin: 0px;
    width: 100%;
  }
}

.alert-heading {
  font-weight: bold;
  font-size: 17px;
}

.final-overview-list {
  .list-heading {
    margin-bottom: -5px;
    font-size: 16px;
  }

  ion-icon {
    background: #333;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 0px;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.1cap;
  }
}

.row {
  overflow-x: hidden;
}

tr.detail-row {
  height: 0 !important;
  border-bottom: none !important;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

tr.detail-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.detail-row:not(.expanded-row):active {
  background: #efefef;
}

ion-modal.auto-height {
  --height: auto;
  z-index: 100000;
}

ion-modal.max-width {
  --width: 100%;
  --max-width: 1300px;
}

ion-modal.max-height {
  --min-height: 80vh;
}

ion-modal.auto-height .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.feedback-selected {
  opacity: 1 !important;
  filter: grayscale(0%) !important;
}

.feedback-line {
  height: 56px;
}

.feedback-button {
  height: 48px !important;
  width: 48px;
  --background-hover: none !important;
  --background: none;
  opacity: 0.7;
  filter: grayscale(80%);

  &:hover {
    filter: grayscale(0%);
    opacity: 1 !important;
  }
}

.mat-bottom-sheet-container {
  margin-top: 80px;
}

ion-footer {
  background: #111d33;
  color: #fff;
  line-height: 40px;
  padding: 0px 30px;
  height: 40px;
  z-index: 400;
  text-align: center;
}

ion-conent {
  --padding-bottom: 30px;
}

.ud-poweredby {
  display: none !important;
}

.logout-modal {
  --max-width: 400px;
}

.card-icon {
  scale: 4;
  margin: 30px 20px;
  color: var(--ion-color-secondary);
}

.table-filtering-bottom {
  --width: 1200px;
}

.search-input {
  width: 350px;
  border-radius: 20px;
  text-indent: 10px;
  border-color: var(--ion-color-primary);
  border-style: solid;
  padding-left: 10px;

  .icon-container {
    width: 50px;
    background-color: var(--ion-color-secondary);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    cursor: pointer;
    position: relative;

    ion-icon {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }
  }

  input {
    margin-left: 4px;
    margin-top: 4px;
    width: 300px;
    margin-bottom: 4px;
    height: 35px;
    background-color: white;
    border-color: transparent;

    &:focus {
      border-color: transparent;

      outline: none;
    }
  }
}

ion-alert {
  z-index: 99999 !important;
}

.anchor {
  cursor: pointer;
  text-decoration: underline;
  color: #7b213e;
}

ion-button[shape="circle"] {
  --border-radius: 50%;
  width: 56px;
  height: 56px;
}
